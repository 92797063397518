<!--定义和标准-->
<template>
    <div class="container definitions-box">
        <div class="row">
          <div class="col-md-12">
              <h1 class="text-capitalize">定义和标准</h1>
          </div>
       </div>
       <div class="row fs-definitions-list">
          <div class="col-md-2 left-box">
              <div class="txt">List</div>
              <ul>
                <li v-for="item in listData" :key="item.id">
                  <a href="">{{item.name}}</a>
                </li>
              </ul>
          </div>
          <div class="col-md-10">
              <div class="rigth-box">
                  <div class="panel-heading">
                      <h5>Abbreviations</h5>
                  </div>
                  <div class="cont-box">
                    <div class="dow-box row">
                        <div class="col-md-8">
                          <button type="button" class="btn btn-primary">下载</button>
                        </div>
                        <div class="input-group col-md-4">
                            <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                              <button type="button" class="btn btn-primary">搜索</button>
                            </div>
                        </div>
                    </div>
                    <table class="table table-bordered">
                         <thead>
                            <tr>
                              <th scope="col" style="width:20%">asas</th>
                              <th scope="col">asas</th>
                            </tr>
                         </thead>
                         <tbody>
                            <tr v-for="item in tableData" :key="item.id">
                              <td>{{item.name}}</td>
                              <td>{{item.age}}</td>
                            </tr>
                         </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                      <ul class="pagination justify-content-end">
                        <li class="page-item disabled">
                          <a class="page-link" href="#" tabindex="-1">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item active"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                          <a class="page-link" href="#">Next</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
              </div>      
          </div>
       </div>
    </div>
</template>
<script>
export default {
  name: "definitions",
  data(){
    return{
      listData:[
        {
          name:"ssss",
          id:1
        },{
          name:"dddd",
          id:2
        },{
          name:"ffff",
          id:3
        }
      ],
      tableData:[
         {
           name:"eee",
           age:"28",
           id:1
         }, {
           name:"eee",
           age:"28",
           id:2
         }, {
           name:"eee",
           age:"28",
           id:3
         }
      ]
    }
  },
  methods:{
      
  },
  mounted(){
    
  }
}
</script>

<style lang="less" scoped>
@import "../assets/less/definitions.less";     
</style>